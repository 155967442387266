.errors-page-container {
  height: 450px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 991.98px) {
  .errors-page-container {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.errors-page-container .errors-page-title {
  text-transform: uppercase;
  font-size: 24px;
}

.errors-page-container .errors-page-img {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 40%;
      -ms-flex: 0 0 40%;
          flex: 0 0 40%;
}

@media only screen and (max-width: 991.98px) {
  .errors-page-container .errors-page-img {
    display: none;
  }
}

.errors-page-container ul {
  list-style: circle;
  padding-left: 20px;
  font-size: 18px;
}

.errors-page-container p {
  font-size: 18px;
  line-height: 140%;
}
